
import { defineComponent } from "vue";
import { Message, Key } from '@element-plus/icons-vue'
import { constant, loading, formSignIn, rule, disable } from '@/store/stateless';

export default defineComponent({
  name: "sign-in",

  setup() {
    return {
      Message,
      Key,
      constant,
      loading,
      formSignIn,
      rule,
      disable
    };
  },
});
